<template>
  <status-badge :status-name="statusName" :type="colorVariant" />
</template>

<script>
import StatusBadge from "@zubut/common/src/components/StatusBadge";
import BillingStatus from "@/constants/services/billing-status";

export default {
  name: "InvoiceStatus",
  components: { StatusBadge },
  props: {
    status: {
      type: Number,
      required: true
    }
  },
  computed: {
    statusName() {
      return BillingStatus.get[this.status];
    },
    colorVariant() {
      switch (this.status) {
        case BillingStatus.NUM_NOT_INVOICED:
          return "blue";
        case BillingStatus.NUM_INVOICED:
          return "green";
        case BillingStatus.NUM_INVOICE_ERROR:
        default:
          return "red";
      }
    }
  }
};
</script>
