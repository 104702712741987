<template>
  <b-modal
    id="invoice-errors-modal"
    :title="modalTitle"
    :visible="value"
    centered
    hide-footer
    header-class="custom-modal-header"
    @change="isVisible => $emit('input', isVisible)"
  >
    <div v-if="invoiceErrors" class="px-3 py-3 z-wrap-text">
      {{ invoiceErrors }}
    </div>
  </b-modal>
</template>

<script>
import { makeShortUUID } from "@/utils/strings";

export default {
  name: "InvoiceErrorsModal",

  props: {
    invoice: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    modalTitle() {
      if (this.invoice) {
        return `Errores de factura ${makeShortUUID(this.invoice.id)}`;
      }
      return "Errores de factura";
    },
    invoiceErrors() {
      return (
        this.invoice?.satError?.message?.violations[0]?.message ||
        this.invoice?.satError?.message?.message ||
        "No se encontraron errores en SATWS"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
#invoice-errors-modal {
  .custom-modal-header {
    padding: 2rem 2rem 0rem 2rem;
    border-bottom: none;
    color: $black;

    .modal-title {
      font-weight: 700;
    }
  }
  .z-wrap-text {
    overflow-x: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
  }
}
</style>
