const ONE_TO_ONE = "Uno a uno";
const MULTIPLE = "Multiple";
const SERVICE = "Servicio";
const WALLET = "Wallet";

const NUM_ONE_TO_ONE = 0;
const NUM_MULTIPLE = 1;
const NUM_SERVICE = 2;
const NUM_WALLET = 3;

const get = {};
get[NUM_ONE_TO_ONE] = ONE_TO_ONE;
get[NUM_MULTIPLE] = MULTIPLE;
get[NUM_SERVICE] = SERVICE;
get[NUM_WALLET] = WALLET;

export default {
  get,
  ONE_TO_ONE,
  MULTIPLE,
  SERVICE,
  WALLET,
  NUM_ONE_TO_ONE,
  NUM_MULTIPLE,
  NUM_SERVICE,
  NUM_WALLET
};
