<template>
  <div class="admin-billings">
    <b-row v-show="createInvoiceType === null" class="invoice-history">
      <b-col>
        <span class="card-title">Historial</span>
      </b-col>
      <b-col cols="12" sm="4" lg="3" class="d-flex justify-content">
        <z-dropdown
          v-model="dateSelected"
          :options="dateOptions"
          variant="white"
          :show-header="false"
          class="w-100"
        />
      </b-col>
      <b-col sm="auto" class="text-right mb-3">
        <b-dropdown
          id="ddown-right"
          v-tooltip="{
            content: tooltipContent,
            placement: 'bottom-start'
          }"
          right
          variant="light"
          class="mx-2 upload-xls-btn"
          no-caret
          @show="tooltipContent = ''"
          @hide="tooltipContent = 'Subir xls de mensajeros'"
        >
          <template #button-content>
            <font-awesome-icon class="excel-icon" icon="excel" />
          </template>
          <b-dropdown-header>
            Facturar como
          </b-dropdown-header>
          <b-dropdown-item @click="uploadCSV(false)">
            Emisor
          </b-dropdown-item>
          <b-dropdown-item @click="uploadCSV(true)">
            Receptor
          </b-dropdown-item>
        </b-dropdown>
        <z-button @click="$router.push({ name: 'one-to-one-invoice' })">
          Crear factura
        </z-button>
      </b-col>
      <b-col md="12">
        <b-row>
          <b-col v-if="showDatepickers" sm="3" lg="3">
            <date-picker
              id="admin-billings-from-date"
              v-model="date.from"
              :language="dateConfig.language"
              calendar-class="date-picker"
              placeholder="Desde"
              input-class="form-control bg-white"
            />
          </b-col>
          <b-col v-if="showDatepickers" sm="3" lg="3">
            <date-picker
              id="admin-billings-until-date"
              v-model="date.until"
              :language="dateConfig.language"
              calendar-class="date-picker"
              placeholder="Hasta"
              input-class="form-control bg-white"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <z-table
          hover
          responsive
          :busy="isLoading"
          :items="tableItems"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <loading-spinner />
            </div>
          </template>
          <template #empty>
            <b-alert show variant="primary">
              <p>No hay facturas registradas en estas fechas</p>
            </b-alert>
          </template>
          <template #head(createdAt)>
            <div
              class="cursor-pointer"
              style="width: 150px;"
              @click="chageSort"
            >
              Fecha
              <font-awesome-icon
                v-show="sortOrder === 'asc'"
                icon="sort"
                size="lg"
              />
              <font-awesome-icon
                v-show="sortOrder === 'desc'"
                icon="sort"
                size="lg"
                flip="vertical"
              />
            </div>
          </template>
          <template #cell(status)="{ item: invoice }">
            <status-invoices :status="invoice.status" />
          </template>
          <template #cell(options)="{ item: invoice }">
            <div class="container-actions">
              <span
                v-show="!invoice.satError"
                class="text actions"
                @click.stop="sendMail(invoice)"
              >
                Reenviar
              </span>
              <span
                v-show="invoice.id === downloadingInvoice"
                class="loading actions"
              >
                <loading-spinner />
              </span>
              <span
                v-show="!invoice.satError && invoice.id !== downloadingInvoice"
                class="text actions"
                @click.stop="download(invoice)"
              >
                Descargar
              </span>
              <span
                v-show="invoice.satError"
                class="text actions"
                @click.stop="errorDetails(invoice)"
              >
                Ver errores
              </span>
              <span
                v-show="
                  invoice.satError &&
                    invoice.billType === invoiceTypes.NUM_ONE_TO_ONE
                "
                class="text actions"
                @click.stop="retry(invoice)"
              >
                Reintentar
              </span>
            </div>
          </template>
        </z-table>
        <z-table-pagination
          :total-rows="totalInvoices"
          :per-page.sync="perPage"
          :current-page.sync="currentPage"
        />
      </b-col>
    </b-row>
    <invoice-errors-modal
      v-model="showInvoiceErrors"
      :invoice="selectedInvoice"
    />
  </div>
</template>

<script>
import DatePicker from "vuejs-datepicker";
import { es } from "vuejs-datepicker/dist/locale";
import fileDownload from "@/fileDownload";
import StatusInvoices from "@/app/components/StatusInvoices";
import ZTable from "@zubut/common/src/components/ZTable.vue";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import DateFilters from "@zubut/common/src/constants/filters/date";
import BillType from "@/constants/invoices/type";
import BillingStatus from "@/constants/services/billing-status";
import InvoiceType from "@/constants/invoices/type";
import notifyMixin from "@/mixins/notify";
import pagination from "@/mixins/pagination";
import {
  dateRange,
  formatISO,
  formatToISO
} from "@zubut/common/src/utils/time";
import Invoices from "@/services/invoices";
import { initialize } from "@/utils/array";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import InvoiceErrorsModal from "./InvoiceErrorsModal";
import parseISO from "date-fns/parseISO";
import { endOfDay } from "date-fns";

export default {
  components: {
    DatePicker,
    StatusInvoices,
    InvoiceErrorsModal,
    ZDropdown,
    ZTable,
    ZTablePagination
  },

  mixins: [notifyMixin("notifications"), pagination],

  data() {
    return {
      isLoading: true,
      downloadingInvoice: null,
      sortBy: "createdAt",
      sortOrder: "desc",
      dateOptions: [
        { value: DateFilters.NUM_TODAY, text: DateFilters.TODAY },
        { value: DateFilters.NUM_YESTERDAY, text: DateFilters.YESTERDAY },
        { value: DateFilters.NUM_THIS_WEEK, text: DateFilters.THIS_WEEK },
        { value: DateFilters.NUM_LAST_WEEK, text: DateFilters.LAST_WEEK },
        { value: DateFilters.NUM_THIS_MONTH, text: DateFilters.THIS_MONTH },
        { value: DateFilters.NUM_LAST_MONTH, text: DateFilters.LAST_MONTH },
        { value: DateFilters.NUM_THIS_YEAR, text: DateFilters.THIS_YEAR },
        { value: DateFilters.NUM_CUSTOM, text: DateFilters.CUSTOM }
      ],
      fields: [
        {
          key: "createdAt",
          label: "Fecha",
          formatter: val => formatISO(val, "d 'de' LLLL 'de' Y H:mm")
        },
        { key: "groupName", label: "Factura" },
        { key: "total", label: "Monto" },
        {
          key: "issuer",
          label: "Emisor",
          formatter: val => (val.name ? val.name : val.rfc)
        },
        {
          key: "receiver",
          label: "Receptor",
          formatter: val => (val.name ? val.name : val.rfc)
        },
        {
          key: "billType",
          label: "Tipo de factura",
          formatter: val => BillType.get[val]
        },
        { key: "status", label: "Estatus" },
        { key: "options", label: "Opciones", thClass: "text-right" }
      ],
      dateSelected: DateFilters.NUM_THIS_WEEK,
      dateConfig: {
        language: es
      },
      invoiceTypes: InvoiceType,
      createInvoiceType: null,
      tooltipContent: "Subir xls de mensajeros",
      placeholderItem: {
        issuer: {
          rfc: "",
          name: ""
        },
        receiver: {
          rfc: ""
        },
        satWsId: "",
        createdAt: null,
        subtotal: 0,
        total: 0,
        groupName: "",
        billType: BillType.NUM_ONE_TO_ONE,
        status: BillingStatus.NUM_NOT_INVOICED,
        satError: false
      },
      selectedInvoice: null,
      showInvoiceErrors: false
    };
  },

  computed: {
    date: {
      get() {
        return this.$store.getters["invoices/date"];
      },
      set(value) {
        return this.$store.commit("invoices/setDate", value);
      }
    },

    invoices() {
      return this.$store.getters["invoices/invoices"];
    },

    showManyInvoice() {
      return (
        this.createInvoiceType === this.invoiceTypes.zubutToDrivers ||
        this.createInvoiceType === this.invoiceTypes.driversToZubut
      );
    },

    showOneToOneInvoice() {
      return this.createInvoiceType === this.invoiceTypes.consolidatedToZubut;
    },

    showDatepickers() {
      return this.dateSelected === DateFilters.NUM_CUSTOM;
    },

    totalInvoices() {
      return this.$store.state.invoices.totalInvoices;
    },

    tableItems() {
      let invoices = initialize({
        value: this.placeholderItem,
        size: this.totalInvoices
      });
      invoices.splice(this.skip, this.invoices.length, ...this.invoices);
      return invoices;
    }
  },

  watch: {
    dateSelected: {
      immediate: true,
      handler(newVal, oldVal) {
        if (
          !oldVal ||
          (newVal !== oldVal && newVal !== DateFilters.NUM_CUSTOM)
        ) {
          this.currentPage = 1;
          this.fetchInvoices();
        }
      }
    },

    date: {
      deep: true,
      handler() {
        if (this.dateSelected === DateFilters.NUM_CUSTOM) {
          this.currentPage = 1;
          this.fetchInvoices();
        }
      }
    },

    currentPage() {
      this.fetchInvoices();
    },

    perPage() {
      this.fetchInvoices();
    }
  },

  methods: {
    chageSort() {
      this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      this.fetchInvoices();
    },

    createInvoice(type) {
      this.createInvoiceType = type;
    },

    cancelCreation() {
      this.createInvoiceType = null;
    },

    generateInvoice() {
      this.createInvoiceType = null;
    },

    uploadCSV(fromReceiver) {
      if (fromReceiver) {
        this.$router.push({
          name: "excel-invoice-receiver"
        });
      } else {
        this.$router.push({
          name: "excel-invoice-issuer"
        });
      }
    },

    sendMail(invoice) {
      this.$store
        .dispatch("invoices/mail", invoice.satWsId)
        .then(res => {
          this.notify({
            type: "success",
            text: "Factura reenviada correctamente"
          });
        })
        .catch(err => {
          const message =
            err?.message || "Error al intentar reenviar la factura";
          this.notify({
            type: "error",
            text: message
          });
        });
    },

    download(invoice) {
      if (this.downloadingInvoice !== null) return;
      this.downloadingInvoice = invoice.id;
      this.$store
        .dispatch("invoices/download", {
          satWsId: invoice.satWsId,
          formats: ["pdf", "xml"]
        })
        .then(res => {
          fileDownload(
            res,
            `invoice_${invoice.satWsId}.zip`,
            "application/zip"
          );
        })
        .catch(err => {
          const message =
            err?.message || "Error al intentar descargar la factura";
          this.notify({
            type: "error",
            text: message
          });
        })
        .finally(() => (this.downloadingInvoice = null));
    },

    errorDetails(invoice) {
      this.selectedInvoice = invoice;
      this.showInvoiceErrors = true;
    },

    retry(invoice) {
      this.$router.push({
        name: "edit-one-to-one-invoice",
        params: { id: invoice.id }
      });
    },

    fetchInvoices() {
      this.isLoading = true;
      const where = this.buildWhereFilter();
      const paginationFilter = this.filter;
      this.$store
        .dispatch("invoices/fetchInvoices", {
          where,
          filter: {
            ...paginationFilter,
            order: `${this.sortBy} ${this.sortOrder}`
          }
        })
        .catch(err => {
          const message =
            err?.message || "Error al intentar obtener los servicios";
          this.notify({
            type: "error",
            text: message
          });
          this.$captureError(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    handleFileUpload(e) {
      const files = e.target.files;
      if (!files.length) return;
      const file = files[0];
      Invoices.parseBillingInfo(file)
        .then(billingInfo => {
          this.notify({
            title: "Archivo cargado exitosamente!",
            type: "success"
          });
          console.log(billingInfo);
        })
        .catch(err => {
          this.notify({
            title: "Error al subir archivo csv",
            text: err.message,
            type: "error"
          });
        })
        .finally(() => (this.$refs.csvFileInput.value = ""));
    },

    buildWhereFilter() {
      const where = {};

      if (this.dateSelected !== DateFilters.NUM_CUSTOM) {
        const newDate = dateRange(this.dateSelected);
        newDate.from = formatToISO(newDate.from);
        newDate.until = formatToISO(newDate.until);
        this.date = newDate;
      }

      if (this.date.from && this.date.until) {
        const from =
          this.date.from instanceof Date
            ? formatToISO(this.date.from)
            : this.date.from;
        const until =
          this.date.until instanceof Date
            ? this.date.until
            : parseISO(this.date.until);
        where.range = {
          from,
          until:
            this.dateSelected === DateFilters.NUM_CUSTOM
              ? formatToISO(endOfDay(until))
              : formatToISO(until)
        };
      }
      return where;
    }
  }
};
</script>

<style lang="scss">
@import "./AdminBillings.scss";
$black: #151515;
$blue: #0076ff;

.container-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  span {
    width: auto;
    max-height: 20px;
    text-align: end;
    margin-right: 10px;
  }
}

.text {
  color: $black;
  font-size: 12px;

  &.header {
    font-weight: 500;
  }

  &.actions {
    color: $blue;

    &:hover {
      cursor: pointer;
    }
  }
}

.loading {
  display: flex;
  justify-content: flex-end;
}
</style>
